//自定义页面
import Login from '@/components/login/Login'
import Main from '@/views/main/Main'
import AppShare from '@/views/app/AppShare'
import menu from '@/router/menu'
//noLogin 不需要鉴权的页面
const routes = [//首页
    {path: '/', name: 'home', component: Main, children: menu, meta: {title: "首页"}, redirect: "/dataKanban"}, //看板
    //登录
    {
        path: '/login', name: 'login', component: Login, meta: {noLogin: true, title: "管理员登录"}
    }, //app分享链接地址
    {
        path: '/app/share', name: 'AppShare', component: AppShare, meta: {noLogin: true, title: "安果系列应用分享"}
    }

]


export default routes;
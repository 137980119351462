<template>
  <div class="parent">
    <!--    logo/名称/描述/分类-->
    <div class="top margin_20">
      <el-image :src="item.logo_url" class="logo_url">

      </el-image>

      <div class="content">
        <span class="app_name">{{ item.name }}</span>
        <span class="app_desc">{{ item.app_desc }}</span>
        <span class="app_classification">
          <el-tag v-for="(value,index) in classification" :key="index" :type="classificationColor[index]" size="mini"
                  class="margin_right_4">
              {{ value }}
          </el-tag>
        </span>
        <span></span>
      </div>

    </div>
    <!--    宣传图，没有不展示-->
    <div class="propaganda margin_20" v-if="propaganda.length>0">
      <el-image v-for="(item,index) in propaganda" :key="index" :src="item" class="propaganda_img"
                :preview-src-list="propaganda">
      </el-image>
    </div>
    <!--    软件介绍。软件版本号、宣传文案 应用权限 隐私政策-->
    <div class="content_center color_dad9d9">
      <p style="margin: 5px">
        <span class="margin_right_4">软件介绍</span>
        <span class="version_name">版本号 {{ item.version_name }}</span>
      </p>


      <el-collapse v-if="appMarketDesc1" @change="handleChange">
        <el-collapse-item :title="appMarketDesc1" name="1" style="margin-left: 20px">
          <pre style="margin-left: 20px">{{ appMarketDesc2 }}</pre>
        </el-collapse-item>
      </el-collapse>

      <p>
        <span class="margin_right_4">{{ item.upload_time }}更新</span>
        <el-button type="text" class="margin_right_4" @click="showAppPermission">应用权限</el-button>
        <el-button type="text" @click="showPolicyUrl">隐私政策</el-button>
      </p>


    </div>
    <div style="height: 70px">

    </div>

    <div class="download">
      <el-button class="download_btn" type="primary" @click="downApp">
        <span class="download_span">下载</span>
      </el-button>
    </div>
    <el-drawer

        title="应用权限"
        :with-header="false"
        :visible.sync="drawer"
        direction="btt">

      <pre class="margin_20">
{{ this.item.permission_description }}
      </pre>
    </el-drawer>

  </div>
</template>

<script>
import {queryIndexById} from "@/api/app/app-list";
import {constants} from "@/common/constants";

export default {
  name: "AppShare",
  data() {
    return {
      id: this.$route.query.id,
      item: {},
      //宣传图
      propaganda: [],
      //分类
      classification: [],
      classificationColor: ["success", "info", "warning"],
      appMarketDesc1: "",
      appMarketDesc2: "",
      drawer: false

    }
  },
  methods: {
    showAppPermission() {
      this.drawer = true;
    },
    showPolicyUrl() {
      window.open(this.item.policy_url)
    },
    downApp() {
      window.open(this.item.down_app_url)
      // window.location.href(this.item.down_app_url);
    },
    handleChange(value) {
      if (value && value.length > 0 && value[0] === '1') {
        this.appMarketDesc1 = this.appMarketDesc1.replace("...", '')
      } else {
        this.appMarketDesc1 = this.appMarketDesc1 + "..."

      }
    },
    fetchData(id) {
      queryIndexById(id).then((response) => {
        this.item = response.data
        //app logo
        if (this.item.logo_url) {
          this.item.logo_url = constants.AG_QN_PUBLIC + this.item.logo_url
        }
        // app 下载地址
        if (this.item.down_app_url) {
          this.item.down_app_url = constants.AG_QN_PUBLIC + this.item.down_app_url
        }
        //app 宣传图
        // fileList: [{name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}, {name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}]
        if (this.item.propaganda) {
          const array = this.item.propaganda.split('|')

          array.map((key) => {
            this.propaganda.push(constants.AG_QN_PUBLIC + key)
          })
        }

        // app 应用分类
        if (this.item.classification) {
          this.classification = this.item.classification.split('|')
        }


        //  全部市场内容
        if (this.item.app_market_desc) {
          if (this.item.app_market_desc.length > 50) {
            this.appMarketDesc1 = this.item.app_market_desc.substring(0, 50)
            this.appMarketDesc2 = this.item.app_market_desc.substring(50)
          } else {
            this.appMarketDesc1 = this.item.app_market_desc
          }
        }


      })

    },
  },
  mounted() {
    this.fetchData(this.id)
  }
}
</script>

<style scoped>

.download_span {
  color: cornsilk;
}


.download_btn {
  width: 95%;
  margin-left: 2.5%;
  margin-right: 2.5%;
  color: #33aef0;
}

.download {
  width: 95%;
  margin-left: 2.5%;
  margin-right: 2.5%;
  bottom: 15px;
  position: fixed;

}

.version_name {
  font-size: 10px;
  color: #555657;
}

.color_dad9d9 {
  background: #dad9d9;
}

.content_center {
  padding: 10px;

  border-radius: 5px;
}

.margin_right_4 {
  margin-right: 4px;
}

.margin_20 {
  margin: 20px;
}


.logo_url {
  width: 100px;
  height: 100px;
}

.app_desc {
  color: #8c939d;
}

.app_name {
  font-size: 24px;
}

.content {
  display: flex;
  flex-direction: column;
}

.top {
  display: flex;
  align-content: center;
  align-items: center;

}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 100px;
  margin: 0;
}

.propaganda_img {
  width: 90px;
  height: 160px;
  border-radius: 15px;
  margin: 5px;

}


</style>
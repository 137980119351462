import Vue from 'vue'
import VueRouter from 'vue-router'


import store from '@/store/store'
import routes from '@/router/router'

import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css'
import router from "@/router/router"; // progress bar styles

/**
 * 重写路由的push方法
 * 解决，相同路由跳转时，报错
 * 添加，相同路由跳转时，触发watch (针对el-menu，仅限string方式传参，形如"view?id=5")
 */

// 保存原来的push函数
const routerPush = VueRouter.prototype.push
// 重写push函数
VueRouter.prototype.push = function push(location) {

    // 这个if语句在跳转相同路径的时候，在路径末尾添加新参数（一些随机数字）
    // 用来触发watch
    if (typeof (location) == "string") {
        var Separator = "&";
        if (location.indexOf('?') === -1) {
            Separator = '?';
        }
        location = location + Separator + "random=" + Math.random();
    }

    // 这个语句用来解决报错
    // 调用原来的push函数，并捕获异常
    return routerPush.call(this, location).catch(error => error)
}


Vue.use(VueRouter)


const createRouter = () => new VueRouter({
    //模式
    mode: 'history', base: process.env.BASE_URL, routes
})
const currentRouter = createRouter()

//设置router的标题 为title
currentRouter.beforeEach((to, from, next) => {
    NProgress.start();
    /* 路由发生变化修改页面title */
    if (to.name) {
        document.title = to.meta.title
    }
    //判断要去的路由有没有noLogin 如果有而且=false 说明不需要鉴权
    if (Object.prototype.hasOwnProperty.call(to.meta, "noLogin") && to.meta.noLogin) {
        //不需要鉴权的页面可以直接展示
        next();
        NProgress.done()
    } else {

        //需要鉴权
        //获取store里面的token
        let token = store.getters.token;
        if (token) {
            next();
            NProgress.done()
        } else {
            next({
                path: '/login', query: {redirect: to.fullPath} // 将刚刚要去的路由path作为参数，方便登录成功后直接跳转到该路由
            });
            NProgress.done()
        }
    }

    next()
})

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
    const newRouter = createRouter()
    currentRouter.matcher = newRouter.matcher // reset router
}

export default currentRouter

<template>
      <router-view/>
</template>

<script>
export default {
  data() {
    return {
      radio: '1'
    };
  }
}

</script>

<style>

</style>

// import home from '@/router/home'
//保持和home里面的内容一致
const tabsListDefault = {
    path: "/dataKanban", name: 'dataKanban', meta: {icon: "s-data", title: "数据看板"}
}
const state = {
    isCollapsed: false, currentMenu: null, tabsList: [tabsListDefault]
};
const mutations = {
    collapseMenu: (state) => {
        state.isCollapsed = !state.isCollapsed;
    }, selectMenu(state, _item) {
        state.currentMenu = (_item.meta.title === tabsListDefault.meta.title) ? null : _item;
        if (_item.meta.title === tabsListDefault.meta.title) {
            state.currentMenu = null
        } else {
            state.currentMenu = _item
            //新增 tabsList
            let result = state.tabsList.findIndex(item => item.name === _item.name)
            result === -1 ? state.tabsList.push(_item) : ""
        }
    }, closeTag(state, _item) {
        //找到
        let result = state.tabsList.findIndex(item => item.name === _item.name);
        //删除
        state.tabsList.splice(result, 1)
    }, closeAllTags(state) {
        //仅仅保留主页

        state.tabsList = [tabsListDefault];
        state.currentMenu = null;
    }
};

const actions = {}


export default {
    namespaced: true, state, mutations, actions
}
